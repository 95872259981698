@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500&family=Poppins:wght@200;300;400&family=Roboto:wght@300;400&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

button{
    @apply border-2 px-[2rem] py-[10px] text-[14px]
}

.active{
    @apply text-TEXT border-b-[3px] border-TEXT
}
.isActive{
  @apply text-TEXT border-l-[3px] border-TEXT
}
li a{
  @apply py-[22px]
}
#form_details{
    @apply flex items-center xl:gap-4 lg:gap-4 gap-2
}
#form_details input{
    @apply border-b-2 border-TEXT w-[100px] xl:w-[200px] lg:w-[200px] focus:outline-none text-[15px] mt-2
}
#bg-effect{
  /* From https://css.glass */
background: rgba(255, 255, 255, 0.06);
border-radius: 16px;
box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(0.4px);
-webkit-backdrop-filter: blur(0.4px);
border: 1px solid rgba(255, 255, 255, 0.08);
}
#services{
    background: rgba(255, 255, 255, 0.08);
    border-radius: 16px;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3.5px);
    -webkit-backdrop-filter: blur(3.5px);
    border: 1px solid rgba(255, 255, 255, 0.4);
}

#sign_bg{
    /* From https://css.glass */
    background: white;
    border-radius: 5px;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.9px);
    -webkit-backdrop-filter: blur(8.9px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    
  }

  #form__input input{
    @apply h-[40px] border-2 border-gray-300 xl:w-[70%] lg:w-[70%] px-2 focus:border-TEXT focus:outline-none
  }

#room_bg{
    background: url('./images/home-bg.jpg');
    background-size: cover;
    background-position: center;
}
#details_bg{
  box-shadow: 0px 0px 5px rgba(0,0,0,0.5);

}

#price__bg{
 /* From https://css.glass */
background: rgba(255, 255, 255, 0);
border-radius: 5px;
box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
backdrop-filter: blur(1.6px);
-webkit-backdrop-filter: blur(1.6px);
border: 1px solid rgba(255, 255, 255, 0.82);
}
.slider {
    overflow: hidden;
    width: 100vw;
    position: relative;
    background-image: linear-gradient(0deg, rgb(0, 0, 0, 0.4), rgba(0, 0, 0, 0.69)),url('./images/log6.jpg');
    background-size: cover;
    background-position: center;

   }
    
   .slider .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    animation: slider 50s infinite;
   }
    
   .slider .slide:nth-child(1) {
      background-image: linear-gradient(0deg, rgba(0,0, 0, 0), rgb(0, 0, 0, 0.4)), url('./images/log1.jpg');
      animation-delay: -5s;
      background-size: cover;
        background-position: center;
   }
   
   .slider .slide:nth-child(2) {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0), rgb(0, 0, 0,0.9)),url('./images/log2.jpg');
      animation-delay: -10s;
      background-size: cover;
    background-position: center;
   }
   
   .slider .slide:nth-child(3) {
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.281), rgba(0, 0, 0, 0.69)),url('./images/log3.jpg');
      animation-delay: -15s;
      background-size: cover;
    background-position: center;
   }
   
   .slider .slide:nth-child(4) {
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.281), rgba(0, 0, 0, 0.69)),url('./images/log4.jpg');
      animation-delay: -25s;
      background-size: cover;
    background-position: center;
   }
   
   .slider .slide:nth-child(5) {
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.281), rgba(0, 0, 0, 0.69)),url('./images/log5.jpg');
      animation-delay: -35s;
      background-size: cover;
    background-position: center;
   }
   #re_bg{
    /* From https://css.glass */
      background: rgba(255, 255, 255, 0.2);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(255, 255, 255, 0.3);
   }
   #contact_bg{
    /* From https://css.glass */
      background: white;
      border-radius: 8px;
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(255, 255, 255, 0.3);
   }
   #receipt_bg{
    background-image: linear-gradient(0deg, rgb(251, 251, 251), rgba(251, 251, 251, 0.87)),url('./images/MEDS-DAEDAL.png');
    background-size: cover;
    background-position: center center;
   }
   #info_bg{
    background-image: linear-gradient(0deg, rgb(134, 96, 27, 0.50), rgba(134, 96, 27, 0.59)),url('https://images.pexels.com/photos/7689684/pexels-photo-7689684.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2');
    background-size: cover;
    background-position: center;
   }
   .admin{
    @apply bg-TEXT px-[10px] py-[5px] rounded-[5px]
   }
   #admin_bg{
    /* From https://css.glass */
        background: rgba(255, 255, 255, 0.65);
        border-radius: 5px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(0.8px);
        -webkit-backdrop-filter: blur(0.8px);
        border: 3px solid rgba(255, 255, 255, 0.05);
   }

   @keyframes slider {
    0%, 16%, 100% {
      transform: translateY(0);
      animation-timing-function: ease;
    }
    20% {
      transform: translateY(-100%);
      animation-timing-function: step-end;
    }
    96% {
      transform: translateX(100%);
      animation-timing-function: ease;
    }
   }
